.nav {
  background-color:#129434;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0 1rem;
}
.site-title {
  font-size: 2rem;
}
.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
}
.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}
.nav li.active {
  background-color: #41c464;
}
.nav li:hover {
  background-color: #59cf78;
}

.hero{
  width: 600px;
  height: 200px;
  position: relative;
}
.hero-dot-play {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.hero-dots {
  display: flex;
  align-items: center;
  gap: 23px;
  list-style: none;
}
.hero-dot {
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 7.5px;
  cursor: pointer
}
.hero-dot.green {
  background: green;
}