*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
.App{

}
.home{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hero-carousel{
  width: 600px;
  height: 200px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.hero-carousel img{
  width: 600px;
  height: 200px;
  object-fit: cover;
  z-index: -1;
  position: absolute;

}
.products-categories-card{
  display: flex;
  flex-direction: row;
  padding: 10px 50px;
  align-items: start;
  justify-content: center;
}
.categories{
  background-color: #129434;
  padding: 20px;
  border-radius: 5px;
}
.categories p{
  margin: 5px;
}
.products{
  padding: 0 10px;
  display: flex;
  flex-flow: wrap;
  gap: 5px;
  width: 1000px;
}
.product{
  background-color: #129434;
  width: 220px;
  height: 300px;
  border-radius: 5px;
}
.product-image{

}
.product-image img{
  width: 220px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}
.product-details{
  padding: 5px;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.footer{
  width: 100%;
  height: 100px;
  background-color: #129434;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer a{
  color: #fff;
}